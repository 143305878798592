import { AppActionType, SET_DIMENSIONS, SET_LOCALE } from "./types";

export const setLocale = (locale: string): AppActionType => {
  return {
    type: SET_LOCALE,
    locale,
  };
};

export const setAppDimensions = (
  windowWidth: number,
  windowHeight: number,
): AppActionType => {
  return {
    type: SET_DIMENSIONS,
    windowWidth,
    windowHeight,
  };
};
