import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";
import ContactBack from "../../../assets/img/contactback.jpg";
import Villa1 from "../../../assets/img/homevilla1.jpg";
import Villa2 from "../../../assets/img/homevilla2.jpg";
import Villa3 from "../../../assets/img/homevilla3.jpg";

const styles = (theme: Theme) =>
  createStyles({
    wideBlock: {
      position: "relative",
      justifyContent: "center",
      alignItems: "space-between !important",
    },
    inner: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      justifyContent: "center",
      alignItems: "center",
    },
  });

export default styles;
