import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";
import Background from "../../../assets/img/thevillaback.jpg";
import Midback from "../../../assets/img/homemidback.jpg";
const styles = (theme: Theme) =>
  createStyles({
    wideBlock: {
      position: "relative",
      justifyContent: "center",
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
    },
    head: {},
    headmin: {
      minHeight: "calc((var(--vh, 1vh) * 100))",
    },
    wide: {
      justifyContent: "center",
      alignItems: "center",
    },
    headTop: {
      backgroundImage: `url(${Background})`,
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      justifyContent: "center",
      alignItems: "center",
    },
    wideInner: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      padding: 10,
      alignItems: "center",
      justifyContent: "flex-start",
    },
    reservationHolder: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      paddingTop: 12,
      paddingBottom: 12,
      justifyContent: "center",
      alignItems: "center",
    },
    pageInnerHolder: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      justifyContent: "center",
      alignItems: "center",
    },
    firstImagesHolder: {
      flexDirection: "row",
    },
    firstImages: {
      minHeight: 400,
      width: "42%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "&.hideSmall": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
    firstImagesImg: {
      position: "relative",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      "& div": {
        width: "98%",
        height: "100%",
        minHeight: "100%",
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        transition: "all 0.3s",
        "&:hover": {
          transform: "scale(1.2)",
        },
      },
    },
    homeMidBack: {
      position: "absolute",
      left: 0,
      top: 300,
      width: "100vw",
      minHeight: "100%",
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      // backgroundImage: `url(${Midback})`,
      zIndex: -5,
    },
    homeMidBottomBack: {
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "100vw",
      height: 280,
      backgroundColor: "white",
      zIndex: -5,
    },
    reservationBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        alignItems: "flex-start",
        flexDirection: "column",
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    reservationSplitter: {
      height: "100%",
      minHeight: 40,
      width: 2,
      backgroundColor: "#f2f3f4",
      marginRight: 26,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    villaMenu: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "space-between",
      width: "100%",
      listStyleType: "none",
      margin: 0,
      padding: 0,
      overflow: "hidden",
      fontFamily: "Articulat CF, sans-serif",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
      },

      "& li": {
        display: "block",
        flex: "0 1 auto",
        flexGrow: 1,
        verticalAlign: "middle",
        textAlign: "center",
        borderRight: "1px solid #FFFFFF",
        padding: 0,
        paddingLeft: 40,
        paddingRight: 40,
        fontSize: 14,
        [theme.breakpoints.down("md")]: {
          fontSize: 12,
          paddingLeft: 20,
          paddingRight: 20,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 9,
          paddingLeft: 10,
          paddingRight: 10,
        },
        [theme.breakpoints.down("xs")]: {
          borderRight: "none",
          paddingTop: 14,
          paddingBottom: 14,
          paddingRight: 0,
          paddingLeft: 0,
        },
      },

      "& li:first-child": {
        paddingLeft: 0,
      },

      "& li:last-child": {
        borderRight: "none",
        borderBottom: "none",
        paddingRight: 0,
      },

      "& li button": {
        marginTop: 10,
        marginBottom: 10,
        textTransform: "uppercase",
        display: "inline-block",
        color: "black",
        fontWeight: "bold",
        textAlign: "center",
        textDecoration: "none",
        padding: 0,
        margin: 0,
        transition: "all .2s ease-in-out",
        fontFamily: "Articulat CF, sans-serif",

        "&::after": {
          display: "block",
          content: "''",
          borderBottom: "solid 3px black",
          transform: "scaleX(0)",
          transition: "transform 250ms ease-in-out",
        },

        "&:hover::after": {
          transform: "scaleX(1)",
        },

        "&.active::after": {
          transform: "scaleX(1)",
        },
      },
    },
    activeLink: {
      fontWeight: "bold",
    },
  });

export default styles;
