import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    rootMenu: {
      display: "table-row",
      listStyleType: "none",
      margin: 0,
      padding: 0,
      overflow: "hidden",

      "& li": {
        display: "table-cell",
        padding: 8,
        paddingLeft: 14,
        paddingRight: 14,
        verticalAlign: "middle",
      },

      "& li a": {
        display: "inline-block",
        textTransform: "uppercase",
        fontWeight: 900,
        color: "black",
        textAlign: "center",
        textDecoration: "none",
        fontFamily: "Mermaid, sans-serif",

        "&::after": {
          display: "block",
          content: "''",
          borderBottom: "solid 3px black",
          transform: "scaleX(0)",
          transition: "transform 250ms ease-in-out",
        },

        "&:hover::after": {
          transform: "scaleX(1)",
        },
      },
    },
    lastLink: {
      color: "black !important",
      fontFamily: "Mermaid, sans-serif",
      border: "1px solid black",
      padding: "6px 14px",
      "&:hover": {
        backgroundColor: "#ffdeba",
        color: "black !important",
      },

      "&::after": {
        display: "block",
        content: "''",
        borderBottom: "none !important",
      },
    },
    fullMenu: {
      display: "block",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    fullMenuHolder: {
      display: "flex",
      flexDirection: "row",
    },
    mobileMenu: {
      display: "block",
      color: "black",
      "&:hover": {
        background: "none",
      },
    },
    mobileNavigation: {
      position: "fixed",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: "#1e1e1e",
      top: 0,
      left: 0,
      zIndex: 9999,
    },
    mobileMenuTitle: {
      color: "#1887bc",
      fontSize: 24,
      fontWeight: "bold",
    },
    rootMobileData: {
      paddingTop: 120,
      [theme.breakpoints.down("md")]: {
        paddingTop: 20,
        paddingLeft: 40,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: 20,
        paddingLeft: 0,
      },
    },
    rootMobileMenu: {
      fontFamily: "Mermaid, sans-serif",
      listStyleType: "none",
      marginTop: 0,
      padding: 0,
      paddingLeft: 0,
      paddingTop: 100,
      overflow: "hidden",
      color: "#ab9878",
      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
        paddingTop: 20,
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        paddingTop: 10,
      },
      "& li": {
        verticalAlign: "middle",
        marginBottom: 20,
      },

      "& li a": {
        width: "100%",
        color: "white",
        textAlign: "center",
        textTransform: "uppercase",
        textDecoration: "none",
        fontSize: 64,
        position: "relative",
        display: "inline-block",
        background:
          "linear-gradient(to right, rgb(171, 152, 120,1), rgba(147, 130, 100, 1) 50%, white 50%);",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundSize: "200% 100%",
        backgroundPosition: "100%",
        transition: "background-position 275ms ease",
        "&:hover": {
          "background-position": "0 100%",
        },
        [theme.breakpoints.down("lg")]: {
          fontSize: 58,
        },
        [theme.breakpoints.down("md")]: {
          fontSize: 52,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 32,
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: 26,
        },
      },
    },
    rootHolder: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
    },
    logoHolder: {
      paddingTop: 80,
      paddingLeft: 40,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 26,
        paddingLeft: 10,
      },
    },
    actualLogo: {
      zIndex: 1,
      width: 288,
      height: 29,
      [theme.breakpoints.down("sm")]: {
        width: 144,
        height: 14,
      },
    },
    closeIcon: {
      marginTop: 40,
      marginRight: 0,
      fontSize: 48,
      color: "white",
      border: "2px solid white",
      padding: 10,
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
        right: 0,
        padding: 0,
        border: "none",
      },
    },
    smallLink: {
      color: "white",
      textDecoration: "none",
      "&:hover": {
        color: "rgba(105, 141, 200, 1)",
      },
    },
    socialLinks: {
      marginTop: 6,
      display: "flex",
      flexDirection: "row",
    },
  });

export default styles;
