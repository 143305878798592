import { Grid, IconButton, withStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import styles from "./NavigationMenu.styles";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "../../redux/reducers";
import classNames from "classnames";
import logo from "../../assets/img/logo-neg.svg";
import Translate from "../../Translate/Translate";

interface State {
  mobileMenuOpen: boolean;
}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number;
  windowHeight: number;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class NavigationMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      mobileMenuOpen: false,
    };
  }

  closeMenu = () => {
    this.setState({
      mobileMenuOpen: false,
    });
  };

  openMenu = () => {
    this.setState({
      mobileMenuOpen: true,
    });
  };

  render() {
    const { classes, windowWidth, windowHeight, currentLocale } = this.props;
    const { mobileMenuOpen } = this.state;
    return (
      <div className={classes.fullMenuHolder}>
        <div
          className={classNames(
            classes.fullMenu,
            "animate__animated animate__fadeIn",
          )}
        >
          <ul className={classes.rootMenu}>
            <li>
              <NavLink to={`/${currentLocale}`}>Home</NavLink>
            </li>
            <li>
              <NavLink
                to={`/${currentLocale}/${Translate.translate("VILLA_LINK")}`}
              >
                {Translate.translate("VILLA_LINK_TEXT")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${currentLocale}/${Translate.translate("GALLERY_LINK")}`}
              >
                {Translate.translate("GALLERY_LINK_TEXT")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${currentLocale}/${Translate.translate("DISCOVER_LINK")}`}
              >
                {Translate.translate("DISCOVER_LINK_TEXT")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${currentLocale}/${Translate.translate("CONTACT_LINK")}`}
              >
                {Translate.translate("CONTACT_LINK_TEXT")}
              </NavLink>
            </li>
            <li>
              <a
                href="https://booking.casaherta.com"
                className={classes.lastLink}
              >
                {Translate.translate("RESERVATIONS_LINK_TEXT")}
              </a>
            </li>
          </ul>
        </div>
        <IconButton
          className={classes.mobileMenu}
          color="inherit"
          aria-label="Menu"
          onClick={(e) => this.openMenu()}
        >
          <MenuIcon className={classes.menuIcon} />
        </IconButton>
        {mobileMenuOpen && (
          <div
            className={classNames(
              classes.mobileNavigation,
              "animate__animated animate__bounceInDown",
            )}
            style={{ width: windowWidth, height: windowHeight }}
          >
            <Grid container className={classes.rootHolder}>
              <Grid
                container
                direction="row"
                style={{ justifyContent: "space-between" }}
              >
                <div className={classes.logoHolder}>
                  <NavLink
                    to={`/${currentLocale}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={logo}
                      className={classNames(
                        classes.actualLogo,
                        "App-logo animate__animated animate__fadeIn",
                      )}
                      alt="logo"
                      style={{ width: 180, height: 75 }}
                    />
                  </NavLink>
                </div>
                <IconButton onClick={() => this.closeMenu()}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </Grid>
              <Grid container direction="row">
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 40,
                  }}
                >
                  <nav className={classes.rootMobileMenu}>
                    <li>
                      <NavLink
                        to={`/${currentLocale}`}
                        onClick={() => this.closeMenu()}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/${currentLocale}/${Translate.translate(
                          "VILLA_LINK",
                        )}`}
                        onClick={() => this.closeMenu()}
                      >
                        {Translate.translate("VILLA_LINK_TEXT")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/${currentLocale}/${Translate.translate(
                          "GALLERY_LINK",
                        )}`}
                        onClick={() => this.closeMenu()}
                      >
                        {Translate.translate("GALLERY_LINK_TEXT")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/${currentLocale}/${Translate.translate(
                          "DISCOVER_LINK",
                        )}`}
                        onClick={() => this.closeMenu()}
                      >
                        {Translate.translate("DISCOVER_LINK_TEXT")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/${currentLocale}/${Translate.translate(
                          "CONTACT_LINK",
                        )}`}
                        onClick={() => this.closeMenu()}
                      >
                        {Translate.translate("CONTACT_LINK_TEXT")}
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href="https://booking.casaherta.com"
                        onClick={() => this.closeMenu()}
                      >
                        {Translate.translate("RESERVATIONS_LINK_TEXT")}
                      </a>
                    </li>
                  </nav>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
    currentLocale: state.app.currentLocale,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(NavigationMenu));
