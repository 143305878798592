import {
  SET_LOCALE,
  AppState,
  AppActionType,
  SET_DIMENSIONS,
} from "../actions/app/types";

export const initialState: AppState = {
  currentLocale: undefined,
  windowWidth: undefined,
  windowHeight: undefined,
};

const app = (state = initialState, action: AppActionType): AppState => {
  switch (action.type) {
    case SET_LOCALE:
      return { ...state, currentLocale: action.locale };
    case SET_DIMENSIONS:
      return {
        ...state,
        windowWidth: action.windowWidth,
        windowHeight: action.windowHeight,
      };
    default:
      return state;
  }
};

export default app;
