const reviews = [
  {
    title: "Zeker en vast een aanrader",
    stars: 4,
    text: (
      <>
        Genoten van deze fantastische plek! Op Ibiza is het altijd een beetje
        afwachten of de beschrijving en de foto’s beantwoorden aan de realiteit
        maar dit is echt een topper van formaat. Heerlijk zwembad, comfy
        ligbedden, een terras met buitenkeuken, gasBBQ en bar, een moderne
        keuken... Er is aan alles gedacht! Super mooie ligging in de bergen maar
        ook niet té afgelegen, met vlakbij enkele coole beach bars en
        restaurants. Zo’n huis zouden we ook wel willen. :) Zeker en vast een
        aanrader!
      </>
    ),
    name: "Viviane De Clerck",
    city: "Antwerp Belgium",
  },
  {
    title: "Wij komen terug!",
    stars: 4,
    text: (
      <>
        Wat een fantastische plaats is dit. Zo relax in de bergen maar toch met
        prachtig zicht op zee. Hier komen we zeker terug!
      </>
    ),
    name: "Lydia De Roeck",
    city: "Stekene Belgium",
  },
];

export default reviews;
