import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  modal: {
    height: "100%",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 9980,
    alignItems: "center",
    justifyContent: "center",
  },
  loadingbox: {
    padding: 30,
    backgroundColor: "white",
    boxShadow: "0 2rem 4rem rgba(0, 0, 0, 0.2)",
    borderRadius: 3,
  },
  spinner: {
    border: "8px solid #f3f3f3",
    borderTop: "8px solid #007aff",
    borderRadius: "50%",
    width: 48,
    height: 48,
    animation: "spin 2s linear infinite",
  },
});

const Loader = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.modal}>
      <div className={classes.loadingbox}>
        <div className={classes.spinner} />
      </div>
    </Grid>
  );
};

export default Loader;
