import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  midTitle: {
    fontFamily: "Mermaid, sans-serif",
    textTransform: "uppercase",
    textAlign: "left",
    fontSize: 78,
    letterSpacing: 0,
    lineHeight: 1.0,
    color: "black",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      fontSize: 78,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 64,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 50,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 38,
    },
  },
}));

const BigTitle = (props: any) => {
  const classes = useStyles();
  return (
    <Typography
      variant={props.variant || "h1"}
      className={classNames(classes.midTitle)}
      style={props.style ? { ...props.style } : {}}
    >
      {props.children}
    </Typography>
  );
};

export default BigTitle;
