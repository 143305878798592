import {createStyles, Theme} from "@material-ui/core";
import {ThemeAbstracts} from "../../config/MuiTheme";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            backgroundColor: "#1e1e1e",
        },
        footerHolder: {
            width: "100%",
            maxWidth: ThemeAbstracts.layout.maxPageWidth,
            padding: "40px 10px",
            color: "white",
            justifyContent: "space-between",
            [theme.breakpoints.down("sm")]: {
                paddingLeft: 10,
                paddingRight: 10,
            },
            fontSize: 12,
        },
        footerLink: {
            textDecoration: "none",
            color: "white",
            "&:hover": {
                textDecoration: "underline",
                color: "#ffdeba",
            },

        },
        footerLinkUpdate: {
            textDecoration: "none",
            color: "white",
            "&:hover": {
                textDecoration: "underline",
                color: "#ffdeba",
            },
            [theme.breakpoints.down("sm")]: {
                display: "block"
            },

        },
        divLogoFooter: {
            display: "flex", justifyContent: "center", width: "100%"
        }
    });

export default styles;
