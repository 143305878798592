import { Button, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  styledBtn: {
    fontSize: 18,
    fontFamily: "Articulat, sans-serif",
    color: "black",
    fontWeight: "bold",
    textTransform: "none",
    marginTop: 20,
    marginBottom: 20,
    minWidth: 220,
    boxShadow: "none",
    backgroundColor: "rgba(0,0,0,0)",
    border: "2px solid black",

    "&::before": {
      position: "absolute",
      display: "block",
      width: "100%",
      height: "100%",
      content: "''",
      border: "solid 3px #a29071",
      transform: "scaleX(0)",
      transition: "transform 250ms ease-in-out",
    },

    "&:hover": {
      border: "2px solid rgba(0,0,0,0)",
      boxShadow: "none",
      backgroundColor: "rgba(0,0,0,0)",
      color: "#a29071",
    },
    "&:hover::before": {
      transform: "scaleX(1)",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 6,
      marginBottom: 6,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  inverseBtn: {
    fontSize: 18,
    fontFamily: "Blaak, sans-serif",
    color: "black",
    textTransform: "none",
    borderRadius: 25,
    marginTop: 20,
    marginBottom: 20,
    minWidth: 220,
    boxShadow: "none",
    backgroundColor: "white",
    border: "2px solid black",

    "&::before": {
      position: "absolute",
      display: "block",
      width: "100%",
      height: "100%",
      borderRadius: 25,
      content: "''",
      transform: "scaleX(0)",
      transition: "transform 250ms ease-in-out",
    },

    "&:hover": {
      border: "2px solid white",
      boxShadow: "none",
      backgroundColor: "black",
      color: "white",
    },
    "&:hover::before": {
      transform: "scaleX(1)",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 6,
      marginBottom: 6,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  whiteBtn: {
    fontSize: 18,
    fontFamily: "Blaak, sans-serif",
    color: "white",
    textTransform: "none",
    backgroundColor: "black",
    borderRadius: 25,
    marginTop: 20,
    marginBottom: 20,
    minWidth: 220,
    boxShadow: "none",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "black",

    "&:hover": {
      color: "black",
      background: "white",
    },
  },
}));

interface OwnProps {
  className?: any;
  inverse?: boolean;
  style?: any;
  children?: any;
  white?: boolean;
  onClick?: any;
}

type Props = OwnProps;

const StyledButton = (props: Props) => {
  const classes = useStyles();
  const { inverse, className, style, white, ...restOfProps } = props;
  return (
    <Button
      variant="contained"
      className={classNames(
        props.inverse
          ? classes.inverseBtn
          : white
          ? classes.whiteBtn
          : classes.styledBtn,
        props.className ? props.className : null,
      )}
      style={style ? style : null}
      {...restOfProps}
    >
      {props.children}
    </Button>
  );
};

export default StyledButton;
