import React from "react";
import {setLocale} from "../redux/actions/app";
import {RootState} from "../redux/reducers";
import {connect} from "react-redux";
import Translate from "../Translate/Translate";
import {Grid} from "@material-ui/core";

interface State {
}

interface OwnProps {
    match?: any;
    children: React.ReactNode;
}

interface StateProps {
    currentLocale: string;
}

interface DispatchProps {
    doSetLocale: (locale: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

class TranslatedComponent extends React.Component<Props, State> {
    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
        if (
            this.props.match &&
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.language
        ) {
            const {language} = this.props.match.params;
            if (language === "nl" || language === "fr" || language === "en") {
                if (language !== this.props.currentLocale) {
                    Translate.setLocale(language);
                    Translate.setI18n(language);
                    this.props.doSetLocale(language);
                }
            }
        }
    };

    render() {
        const {currentLocale} = this.props;
        return <div key={currentLocale}>{this.props.children}</div>;
    }
}

const mapStateToProps = (state: RootState) => {
    return {currentLocale: state.app.currentLocale};
};

function mapDispatchToProps(dispatch: any) {
    return {
        doSetLocale: (locale: string) => {
            dispatch(setLocale(locale));
        },
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(// @ts-ignore
    TranslatedComponent);
