import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Footer from "../../partials/Footer";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./Gallery.styles";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import classNames from "classnames";
import ContactForm from "../contact/ContactForm";
import Pool1 from "../../../assets/img/pool1.jpg";
import Pool2 from "../../../assets/img/pool2.jpg";
import Pool3 from "../../../assets/img/pool3.jpg";
import Living1 from "../../../assets/img/livingroom1.jpg";
import Living2 from "../../../assets/img/livingroom2.jpg";
import Living3 from "../../../assets/img/livingroom3.jpg";
import Terrace1 from "../../../assets/img/terrace1.jpg";
import Terrace2 from "../../../assets/img/terrace2.jpg";
import Terrace3 from "../../../assets/img/terrace3.jpg";
import Room1 from "../../../assets/img/room1.jpg";
import Room2 from "../../../assets/img/room2.jpg";
import Room3 from "../../../assets/img/room3.jpg";
import Villa1 from "../../../assets/img/homevilla1.jpg";
import Villa2 from "../../../assets/img/homevilla2.jpg";
import Villa3 from "../../../assets/img/homevilla3.jpg";
import Gallery1 from "../../../assets/img/gallery1.jpg";
import Gallery18 from "../../../assets/img/gallery18.jpg";
import Gallery2 from "../../../assets/img/gallery2.jpg";
import Gallery3 from "../../../assets/img/gallery3.jpg";
import Gallery4 from "../../../assets/img/gallery4.jpg";
import Gallery5 from "../../../assets/img/gallery5.jpg";
import Gallery6 from "../../../assets/img/gallery6.jpg";
import Gallery7 from "../../../assets/img/gallery7.jpg";
import Gallery8 from "../../../assets/img/gallery8.jpg";
import Gallery9 from "../../../assets/img/gallery9.jpg";
import Gallery10 from "../../../assets/img/gallery10.jpg";
import Gallery11 from "../../../assets/img/gallery11.jpg";
import Gallery12 from "../../../assets/img/gallery12.jpg";
import Gallery13 from "../../../assets/img/gallery13.jpg";
import Gallery14 from "../../../assets/img/gallery14.jpg";
import Gallery15 from "../../../assets/img/gallery15.jpg";
import Gallery16 from "../../../assets/img/gallery16.jpg";
import Gallery17 from "../../../assets/img/gallery17.jpg";
import TranslatedComponent from "../../TranslatedComponent";
import Translate from "../../../Translate/Translate";

interface State {
  height: number;
}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Gallery extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      height: 0,
    };
  }

  handleScroll = () => {};

  componentDidMount() {
    this.setState({
      height: window.innerHeight,
    });
  }

  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const { height } = this.state;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? height : windowHeight || 700;

    const images = [
      {
        img: Villa1,
      },
      {
        img: Villa2,
        portrait: true,
      },
      {
        img: Villa3,
      },
      {
        img: Living1,
      },

      {
        img: Living3,
      },
      {
        img: Living2,
        portrait: true,
      },
      {
        img: Room3,
        portrait: true,
      },
      {
        img: Room1,
      },
      {
        img: Room2,
      },
      {
        img: Pool1,
      },
      {
        img: Pool3,
      },
      {
        img: Pool2,
        portrait: true,
      },
      {
        img: Terrace1,
      },
      {
        img: Terrace2,
        portrait: true,
      },
      {
        img: Terrace3,
      },
      {
        img: Gallery13,
        portrait: true,
      },
      {
        img: Gallery1,
      },

      {
        img: Gallery2,
      },

      {
        img: Gallery3,
      },

      {
        img: Gallery4,
      },
      {
        img: Gallery14,
        portrait: true,
      },
      {
        img: Gallery15,
        portrait: true,
      },
      {
        img: Gallery5,
      },

      {
        img: Gallery6,
      },

      {
        img: Gallery7,
      },
      {
        img: Gallery16,
        portrait: true,
      },
      {
        img: Gallery8,
      },
      {
        img: Gallery17,
        portrait: true,
      },
      {
        img: Gallery9,
      },

      {
        img: Gallery10,
      },
      {
        img: Gallery11,
      },

      {
        img: Gallery12,
      },
      {
        img: Gallery18,
        portrait: true,
      },
    ];

    return (
      <TranslatedComponent {...this.props}>
        <PageView>
          <Helmet>
            <title>
              Casa Herta d'Ibiza | Gallery - Experience everything Casa Herta
              D'Ibiza has to offer
            </title>
            <meta
              name="title"
              content="Casa Herta d'Ibiza | Gallery - Experience everything Casa Herta D'Ibiza has to offer"
            />
            <meta
              name="description"
              content="Casa Herta d'Ibiza is a luxurious villa in the heart of Ibiza. Book your stay now and experience a lavish Ibiza lifestyle. The house offers a swimmingpool, bedrooms, bathrooms and suits up to 7 guests."
            />
            <meta
              name="keywords"
              content="casa, herta, ibiza, home, vacation, holiday, rental, summer, stay, island, beaches, party"
            />
          </Helmet>
          <Grid
            container
            className={classNames(classes.wideBlock, classes.head)}
            style={{ minHeight: theWindowHeight }}
          >
            <Grid
              container
              style={{
                flex: 0,
              }}
            >
              <Header />
            </Grid>
            <Grid
              container
              direction="row"
              className={classNames(classes.wide, classes.headTop)}
              style={{
                flex: 1,
              }}
            >
              <Grid
                container
                className={classes.pageInnerHolder}
                style={{ marginRight: 20, marginTop: 60 }}
              >
                {images.map((item, key) => {
                  return (
                    <Grid item xs={12} md={item.portrait ? 2 : 5} key={key}>
                      <div className={classes.imgHolder}>
                        <div className={classes.theImgInner}>
                          <div
                            className={classes.theImg}
                            style={{ backgroundImage: `url(${item.img})` }}
                          />
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              flex: 0,
              display: "flex",
              backgroundColor: "white",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              className={classes.pageInnerHolder}
              style={{
                marginTop: 120,
                marginBottom: 120,
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <ContactForm
                title={Translate.translate("CONTACTUS")}
                sendText={Translate.translate("CONTACTSEND")}
                titleVariant="h2"
              />
            </Grid>
          </Grid>
          <Footer />
        </PageView>
      </TranslatedComponent>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Gallery));
