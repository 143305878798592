import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  tinyTitle: {
    fontFamily: "Articulat, sans-serif",
    textAlign: "left",
    fontSize: 20,
    letterSpacing: 0,
    lineHeight: 1.2,
    color: "black",
    width: "100%",
    fontWeight: "bold",
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
}));

const TinyTitle = (props: any) => {
  const classes = useStyles();
  return (
    <Typography
      variant={props.variant || "h3"}
      className={classNames(classes.tinyTitle)}
      style={props.style ? { ...props.style } : {}}
    >
      {props.children}
    </Typography>
  );
};

export default TinyTitle;
