import {ButtonBase, Grid, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {RootState} from "../../../redux/reducers";
import Footer from "../../partials/Footer";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./TheVilla.styles";
import {connect} from "react-redux";
import Helmet from "react-helmet";
import classNames from "classnames";
import BigTitle from "../../text/BigTitle";
import TinyTitle from "../../text/TinyTitle";
import NormalTitle from "../../text/NormalTitle";
import NormalText from "../../text/NormalText";
import ContactForm from "../contact/ContactForm";
import Pool1 from "../../../assets/img/pool1.jpg";
import Pool2 from "../../../assets/img/pool2.jpg";
import Pool3 from "../../../assets/img/pool3.jpg";
import Pool4 from "../../../assets/img/room1.jpg";

import Living1 from "../../../assets/img/livingroom1.jpg";
import Living2 from "../../../assets/img/livingroom2.jpg";
import Living3 from "../../../assets/img/livingroom3.jpg";
import Terrace1 from "../../../assets/img/terrace1.jpg";
import Terrace2 from "../../../assets/img/terrace2.jpg";
import Terrace3 from "../../../assets/img/terrace3.jpg";
import Room1 from "../../../assets/img/room1.jpg";
import Room2 from "../../../assets/img/room2.jpg";
import Room3 from "../../../assets/img/room3.jpg";
import TranslatedComponent from "../../TranslatedComponent";
import Translate from "../../../Translate/Translate";
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {BrowserView, MobileView, isBrowser, isMobile} from 'react-device-detect';


interface State {
    height: number;
    activeItem: number;
}

interface OwnProps {
    classes?: any;
}

interface StateProps {
    windowWidth: number | undefined;
    windowHeight: number | undefined;
    currentLocale: string;
}

type Props = OwnProps & StateProps;

class TheVilla extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            height: 0,
            activeItem: 0,
        };
    }

    handleScroll = () => {
    };

    componentDidMount() {
        this.setState({
            height: window.innerHeight,
        });
    }

    setActiveItem = (activeItem) => {
        this.setState({activeItem});
    };

    render() {
        const {classes, windowHeight, windowWidth} = this.props;
        const {height, activeItem} = this.state;
        const mobile = windowWidth && windowWidth < 700 ? true : false;
        const theWindowHeight = mobile ? height : windowHeight || 700;

        const data = [
            {
                id: 1,
                title: Translate.translate("VILLAPOOL"),
                desc: Translate.translate("VILLATEXT1"),
                specs: Translate.translate("VILLASPECS1"),
                img1: Pool1,
                img2: Pool2,
                img3: Pool3,
                img4: Pool4
            },
            {
                id: 2,
                title: Translate.translate("VILLATERRACE"),
                desc: Translate.translate("VILLATEXT2"),
                specs: Translate.translate("VILLASPECS2"),
                img1: Terrace1,
                img2: Terrace2,
                img3: Terrace3,
            },
            {
                id: 3,
                title: Translate.translate("VILLALIVING"),
                desc: Translate.translate("VILLATEXT3"),
                specs: Translate.translate("VILLASPECS3"),
                img1: Living1,
                img2: Living2,
                img3: Living3,
            },
            {
                id: 4,
                title: Translate.translate("VILLALAUNDRY"),
                desc: Translate.translate("VILLATEXT4"),
                specs: Translate.translate("VILLASPECS4"),
                img1: Room1,
                img2: Room3,
                img3: Room2,
            },
            {
                id: 5,
                title: Translate.translate("VILLAROOMS"),
                desc: Translate.translate("VILLATEXT5"),
                specs: Translate.translate("VILLASPECS5"),
                img1: Room1,
                img2: Room3,
                img3: Room2,
            },
        ];

        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        return (
            <TranslatedComponent {...this.props}>
                <PageView>
                    <Helmet>
                        <title>
                            Casa Herta d'Ibiza | Home - Book your Ibiza hideaway now!
                        </title>
                        <meta
                            name="title"
                            content="Casa Herta d'Ibiza | Home - Book your Ibiza hideaway now!"
                        />
                        <meta
                            name="description"
                            content="Casa Herta d'Ibiza is a luxurious villa in the heart of Ibiza. Book your stay now and experience a lavish Ibiza lifestyle. The house offers a swimmingpool, bedrooms, bathrooms and suits up to 7 guests."
                        />
                        <meta
                            name="keywords"
                            content="casa, herta, ibiza, home, vacation, holiday, rental, summer, stay, island, beaches, party"
                        />
                    </Helmet>
                    <Grid
                        container
                        className={classNames(
                            classes.wideBlock,
                            classes.head,
                            classes.headmin,
                        )}
                    >
                        <Grid
                            container
                            style={{
                                flex: 0,
                            }}
                        >
                            <Header/>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            className={classNames(classes.wide, classes.headTop)}
                            style={{
                                flex: 1,
                                minHeight: 600,
                            }}
                        >
                            <div style={{textAlign: "center"}}>
                                <BigTitle
                                    style={{
                                        maxWidth: 700,
                                        textAlign: "center",
                                        marginBottom: 20,
                                        marginTop: 120,
                                    }}
                                >
                                    MI CASA, SU CASA
                                </BigTitle>
                                <TinyTitle
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    {Translate.translate("VILLAEXPLORE")}
                                </TinyTitle>
                            </div>
                        </Grid>
                        <Grid
                            container
                            style={{
                                flex: 0,
                                display: "flex",
                                backgroundColor: "#ffdeba",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Grid container className={classes.reservationHolder}>
                                <nav>
                                    <ul className={classes.villaMenu}>
                                        <li>
                                            <ButtonBase
                                                onClick={() => this.setActiveItem(0)}
                                                className={activeItem === 0 ? "active" : ""}
                                            >
                                                {Translate.translate("VILLAPOOL")}
                                            </ButtonBase>
                                        </li>
                                        <li>
                                            <ButtonBase
                                                onClick={() => this.setActiveItem(1)}
                                                className={activeItem === 1 ? "active" : ""}
                                            >
                                                {Translate.translate("VILLATERRACE")}
                                            </ButtonBase>
                                        </li>
                                        <li>
                                            <ButtonBase
                                                onClick={() => this.setActiveItem(2)}
                                                className={activeItem === 2 ? "active" : ""}
                                            >
                                                {Translate.translate("VILLALIVINGR")}
                                            </ButtonBase>
                                        </li>
                                        <li>
                                            <ButtonBase
                                                onClick={() => this.setActiveItem(3)}
                                                className={activeItem === 3 ? "active" : ""}
                                            >
                                                {Translate.translate("VILLALAUNDRY")}
                                            </ButtonBase>
                                        </li>
                                        <li>
                                            <ButtonBase
                                                onClick={() => this.setActiveItem(4)}
                                                className={activeItem === 4 ? "active" : ""}
                                            >
                                                {Translate.translate("VILLAROOMS")}
                                            </ButtonBase>
                                        </li>
                                    </ul>
                                </nav>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        style={{
                            flex: 0,
                            display: "flex",
                            backgroundColor: "white",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            container
                            className={classes.pageInnerHolder}
                            style={{marginLeft: 20, marginRight: 20}}
                        >
                            <div style={{width: "100%"}}>
                                <NormalTitle
                                    style={{
                                        textAlign: "center",
                                        marginTop: 100,
                                        marginBottom: 20,
                                    }}
                                    variant="h2"
                                >
                                    {data[activeItem].title}
                                </NormalTitle>
                            </div>
                            <div>
                                <NormalText style={{textAlign: "center", maxWidth: 1000}}>
                                    {data[activeItem].desc}
                                </NormalText>
                            </div>
                            <div
                                style={{
                                    width: "100%",
                                    textAlign: "center",
                                    marginTop: 50,
                                    marginBottom: 150,
                                }}
                            >
                                {data[activeItem].specs}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        style={{
                            flex: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: "white",
                            zIndex: -10,
                        }}
                    >
                        <div className={classes.homeMidBack}/>
                        <Grid container className={classes.pageInnerHolder}>
                            <Grid container className={classes.firstImagesHolder}
                                  style={{display: isMobile ? "none" : "auto"}}>
                                <div className={classes.firstImages}>
                                    <div className={classes.firstImagesImg}>
                                        <div
                                            className="img1"
                                            style={{
                                                backgroundImage: `url(${data[activeItem].img1})`,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={classNames(classes.firstImages, "hideSmall")}
                                    style={{width: "16%"}}
                                >
                                    <div className={classes.firstImagesImg}>
                                        <div
                                            className="img2"
                                            style={{
                                                backgroundImage: `url(${data[activeItem].img2})`,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={classNames(classes.firstImages, "hideSmall")}>
                                    <div className={classes.firstImagesImg}>
                                        <div
                                            className="img3"
                                            style={{
                                                backgroundImage: `url(${data[activeItem].img3})`,
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid container className={classes.firstImagesHolder}
                                  style={{display: isMobile ? "auto" : "none"}}>
                                    <Carousel>
                                        <img src={data[activeItem].img1}/>
                                        <img src={data[activeItem].img3}/>
                                        <img src={data[activeItem].img4}/>

                                        {/*<div className={classes.firstImages}>*/}

                                        {/*<div*/}
                                        {/*    className="img1"*/}
                                        {/*    style={{*/}
                                        {/*        backgroundImage: `url(${data[activeItem].img1})`,*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        {/*</div>*/}
                                        {/*<div className={classes.firstImages}>*/}
                                        {/*<div*/}
                                        {/*    className="img1"*/}
                                        {/*    style={{*/}
                                        {/*        backgroundImage: `url(${data[activeItem].img2})`,*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        {/*</div>*/}
                                        {/*<div className={classes.firstImages}>*/}
                                        {/*<div*/}
                                        {/*    className="img1"*/}
                                        {/*    style={{*/}
                                        {/*        backgroundImage: `url(${data[activeItem].img3})`,*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        {/*</div>*/}
                                    </Carousel>
                            </Grid>

                        </Grid>
                        <Grid
                            container
                            className={classes.pageInnerHolder}
                            style={{ minHeight: isMobile ? 0 : 100}}
                        ></Grid>
                    </Grid>
                    <Grid
                        container
                        style={{
                            flex: 0,
                            display: "flex",
                            backgroundColor: "white",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            container
                            className={classes.pageInnerHolder}
                            style={{
                                marginTop: 120,
                                marginBottom: 120,
                                marginLeft: 20,
                                marginRight: 20,
                            }}
                        >
                            <ContactForm
                                title={Translate.translate("CONTACTUS")}
                                sendText={Translate.translate("CONTACTSEND")}
                                titleVariant="h2"
                            />
                        </Grid>
                    </Grid>
                    <Footer/>
                </PageView>
            </TranslatedComponent>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        windowWidth: state.app.windowWidth,
        windowHeight: state.app.windowHeight,
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(TheVilla));
