const reviews = [
  {
    title: "Fortement recommandé",
    stars: 4,
    text: (
      <>
        J'ai apprécié cet endroit fantastique! A Ibiza c'est toujours un peu
        attendez de voir si la description et les photos correspondent à la
        réalité mais celui-ci est vraiment top. Belle piscine, confortable
        transats, une terrasse avec cuisine extérieure, barbecue à gaz et bar,
        un cuisine... Tout a été pensé ! Super bel emplacement dans les
        montagnes mais pas trop éloigné non plus, avec quelques bars de plage
        sympas et le restaurant. Nous aimerions une maison comme ça. :)
        Certainement un conseillé!
      </>
    ),
    name: "Viviane De Clerck",
    city: "Antwerp Belgium",
  },
  {
    title: "Nous reviendrons!",
    stars: 4,
    text: (
      <>
        Quel endroit fantastique c'est. Alors détendez-vous dans les montagnes
        mais toujours avec belle vue sur la mer. Nous reviendrons certainement
        ici!
      </>
    ),
    name: "Lydia De Roeck",
    city: "Stekene Belgium",
  },
];

export default reviews;
