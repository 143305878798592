import { createStyles, Theme } from "@material-ui/core";
import { isSafari } from "react-device-detect";
import { ThemeAbstracts } from "../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#ffffff",

      "& .childScroll": {
        scrollSnapAlign: isSafari ? "none" : "start",
        scrollSnapStop: isSafari ? "normal" : "always",
        width: "100%",
        minWidth: "100%",
      },

      "& section": {
        width: "100%",
        minWidth: "100%",
      },
    },
    pageHolder: {
      justifyContent: "flex-start",
      alignItems: "center",
      zIndex: 5,
      position: "relative",
      overflowX: "hidden",
    },
  });

export default styles;
