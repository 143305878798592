import { combineReducers, Reducer, AnyAction } from "redux";
import app from "./app";
import { AppState } from "../actions/app/types";

export interface RootState {
  app: AppState;
}

const appReducer: Reducer = combineReducers<RootState>({
  app,
});

const rootReducer = (state: any, action: AnyAction): Reducer => {
  const { type } = action;
  if (type && type === "RESET_APP") {
    const { app, layout } = state;
    state = { app, layout };
  }
  return appReducer(state, action);
};

export default rootReducer;
