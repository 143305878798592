import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: 120,
    },
    rootBlack: {
      backgroundColor: "#1e1e1d",
    },
    headerHolder: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      padding: 10,
      alignItems: "center",
      color: "white",
      justifyContent: "flex-end",
    },
    logoHolder: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    logoHolderMobile: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    menuHolder: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: 10,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    menuHolderMobile: {
      display: "none",
      flexDirection: "row",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
    },
    languageHolderMobile: {
      width: 60,
    },
  });

export default styles;
