import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";
import Background from "../../../assets/img/thevillaback.jpg";
import Midback from "../../../assets/img/homemidback.jpg";
const styles = (theme: Theme) =>
  createStyles({
    wideBlock: {
      position: "relative",
      justifyContent: "center",
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
    },
    head: {},
    wide: {
      justifyContent: "center",
      alignItems: "center",
    },
    headTop: {
      justifyContent: "center",
      alignItems: "center",
    },
    wideInner: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      padding: 10,
      alignItems: "center",
      justifyContent: "flex-start",
    },
    reservationHolder: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      paddingTop: 4,
      paddingBottom: 4,
      justifyContent: "center",
      alignItems: "center",
    },
    pageInnerHolder: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      justifyContent: "center",
      alignItems: "center",
    },
    imgHolder: {
      height: 400,
      width: "100%",
      backgroundColor: "white",
      marginBottom: 20,
      display: "flex",
      justifyContent: "center",
    },
    theImgInner: {
      marginLeft: 10,
      marginRight: 10,
      width: "100%",
      height: 400,
      overflow: "hidden",
      backgroundColor: "white",
      position: "relative",
    },
    theImg: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: 400,
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      transition: "all 0.3s",
      "&:hover": {
        transform: "scale(1.2)",
      },
    },
  });

export default styles;
