import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { RootState } from "../../../redux/reducers";
import Footer from "../../partials/Footer";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./Contact.styles";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import classNames from "classnames";
import ContactForm from "../contact/ContactForm";
import GoogleMapZ from "./GoogleMapZ";
import TranslatedComponent from "../../TranslatedComponent";
import Translate from "../../../Translate/Translate";

interface State {
  height: number;
}

interface OwnProps {
  classes?: any;
}

interface StateProps {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
  currentLocale: string;
}

type Props = OwnProps & StateProps;

class Contact extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      height: 0,
    };
  }

  componentDidMount() {
    this.setState({
      height: window.innerHeight,
    });
  }

  render() {
    const { classes, windowHeight, windowWidth } = this.props;
    const { height } = this.state;
    const mobile = windowWidth && windowWidth < 700 ? true : false;
    const theWindowHeight = mobile ? height : windowHeight || 700;
    return (
      <TranslatedComponent {...this.props}>
        <PageView>
          <Helmet>
            <title>
              Casa Herta d'Ibiza | {Translate.translate("CONTACT_TITLE")}
            </title>
            <meta
              name="title"
              content="Casa Herta d'Ibiza | Contact - Contact us and book your Ibiza hideaway now!"
            />
            <meta
              name="description"
              content="Casa Herta d'Ibiza is a luxurious villa in the heart of Ibiza. Book your stay now and experience a lavish Ibiza lifestyle. The house offers a swimmingpool, bedrooms, bathrooms and suits up to 7 guests."
            />
            <meta
              name="keywords"
              content="casa, herta, ibiza, home, vacation, holiday, rental, summer, stay, island, beaches, party"
            />
          </Helmet>
          <Header />
          <Grid
            container
            className={classNames(classes.wideBlock)}
            style={{
              minHeight: theWindowHeight - 100,
              // overflowY: "hidden",
              position: "relative",
              marginBottom: 120,
            }}
          >
            <div className={classes.contactBack} />
            <div className={classes.contactBottomBack} />
            <Grid
              container
              className={classes.midSH}
              style={{ marginBottom: 120, marginTop: 120 }}
            >
              <ContactForm
                title={Translate.translate("CONTACTUS")}
                sendText={Translate.translate("CONTACTSEND")}
              />
            </Grid>
            <Grid container className={classes.pageInnerHolder}>
              <Grid container className={classes.firstImagesHolder}>
                <div className={classes.firstImages}>
                  <div className={classes.firstImagesImg}>
                    <div className="img1" />
                  </div>
                </div>
                <div
                  className={classNames(classes.firstImages, "hideSmall")}
                  style={{ width: "16%" }}
                >
                  <div className={classes.firstImagesImg}>
                    <div className="img2" />
                  </div>
                </div>
                <div className={classNames(classes.firstImages, "hideSmall")}>
                  <div className={classes.firstImagesImg}>
                    <div className="img3" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className={classNames(classes.wideBlock)}
            style={{
              minHeight: mobile ? theWindowHeight : 620,
              // overflowY: "hidden",
              position: "relative",
            }}
          >
            <GoogleMapZ
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCU8QTI3znyOJExk39C_bgzf1znyg1voxo&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={
                <div style={{ height: mobile ? theWindowHeight : 620 }} />
              }
              containerElement={
                <div
                  style={{
                    width: "100%",
                    height: mobile ? theWindowHeight : 620,
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
          </Grid>
          <Footer />
        </PageView>
      </TranslatedComponent>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    windowWidth: state.app.windowWidth,
    windowHeight: state.app.windowHeight,
    currentLocale: state.app.currentLocale,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Contact));
