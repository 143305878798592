export class Locale {
  constructor(
    public i18n: string,
    public language: string,
    public iso: string,
    public translations: {} = {},
  ) {}
}

export class LocaleSwitch {
  constructor(
    public value: string,
    public i18n: string,
    public current: boolean,
  ) {}
}

class Translate {
  constructor(
    private _currentI18n: string | undefined = undefined,
    private _currentLocale: Locale | undefined = undefined,
    private _locales: Locale[] = [],
  ) {}

  replaceStringWithParams(s: string, params: any): string {
    for (var p in params) {
      var exp = new RegExp("%" + p + "%", "g");
      s = s.replace(exp, params[p]);
    }
    return s;
  }

  translate(
    k: string = "",
    params: any = undefined,
    forceLanguage: string | undefined = undefined,
  ) {
    try {
      if (this._currentLocale && this._currentI18n) {
        let useLocale = this._currentLocale;
        if (forceLanguage) {
          for (const loc of this._locales) {
            if (loc.i18n === forceLanguage) {
              useLocale = loc;
              break;
            }
          }
        }
        const s = useLocale.translations[k.toUpperCase()];
        return s ? (params ? this.replaceStringWithParams(s, params) : s) : k;
      } else {
        return k;
      }
    } catch {
      return "";
    }
  }

  setI18n(i18n: string) {
    this._currentI18n = i18n;
  }

  setLocales(locales: Locale[] = []) {
    if (locales && locales.length) {
      this._locales = locales;
      if (this._currentI18n) {
        this.setLocale(this._currentI18n);
      }
    } else {
      throw new Error(
        "Please provide array of Locales -> translate.createLocale",
      );
    }
  }

  getLocales(): Locale[] {
    return this._locales;
  }

  getCurrentLocale(): Locale | undefined {
    return this._currentLocale;
  }

  getLocaleSwitch(language: boolean): LocaleSwitch[] {
    let locs: LocaleSwitch[] = [];
    if (this._locales && this._locales.length) {
      for (let locale of this._locales) {
        locs.push(
          new LocaleSwitch(
            language ? locale.language : locale.iso,
            locale.i18n,
            this._currentLocale
              ? locale.i18n === this._currentLocale.i18n
                ? true
                : false
              : false,
          ),
        );
      }
    }
    return locs;
  }

  setLocale(i18n: string = "") {
    if (this._locales && this._locales.length) {
      for (let locale of this._locales) {
        if (locale.i18n === i18n) {
          this._currentLocale = locale;
          this._currentI18n = i18n;
        }
      }
    } else {
      throw new Error("Locales not set");
    }
  }
}

const translate = new Translate();

export default translate;
