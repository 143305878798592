import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    menuHolder: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        display: "block",
          marginTop: 0,
      },
      color: "black",
      marginTop: 10,
    },
    menuPaper: {
      marginTop: 10,
    },
    languageIcon: {
      marginRight: 5,
    },
    currentLanguage: {
      display: "block",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    theIconButton: {
      "&:hover": {
        borderRadius: 5,
      },
    },
  });

export default styles;
