import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";
import Background from "../../../assets/img/homebanner.jpg";
import Villa1 from "../../../assets/img/homevilla1.jpg";
import Villa2 from "../../../assets/img/homevilla2.jpg";
import Villa3 from "../../../assets/img/homevilla3.jpg";
import Villa4 from "../../../assets/img/homevilla4.jpg";
import Villa5 from "../../../assets/img/homevilla5.jpg";
import Villa6 from "../../../assets/img/homevilla6.jpg";
import Midback from "../../../assets/img/homemidback.jpg";
const styles = (theme: Theme) =>
  createStyles({
    wideBlock: {
      position: "relative",
      justifyContent: "center",
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
    },
    head: {},
    headmin: {
      minHeight: "calc((var(--vh, 1vh) * 100))",
    },
    wide: {
      justifyContent: "center",
      alignItems: "center",
    },
    headTop: {
      backgroundImage: `url(${Background})`,
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      justifyContent: "center",
      alignItems: "center",
    },
    wideInner: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      padding: 10,
      alignItems: "center",
      justifyContent: "flex-start",
    },
    reservationHolder: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      paddingTop: 12,
      paddingBottom: 12,
      justifyContent: "center",
      alignItems: "center",
    },
    pageInnerHolder: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      justifyContent: "center",
      alignItems: "center",
    },
    reviewHolder: {
      display: "flex",
      justifyContent: "center",
    },
    pageInnerReviews: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      justifyContent: "center",
      alignItems: "center",
    },
    firstImagesHolder: {
      flexDirection: "row",
    },
    firstImages: {
      minHeight: 400,
      width: "42%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "&.hideSmall": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
    firstImagesImg: {
      position: "relative",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      "& div": {
        width: "98%",
        height: "100%",
        minHeight: "100%",
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        transition: "all 0.3s",
        "&:hover": {
          transform: "scale(1.2)",
        },
      },
      "& div.img1": {
        backgroundImage: `url(${Villa1})`,
      },
      "& div.img2": {
        backgroundImage: `url(${Villa2})`,
      },
      "& div.img3": {
        backgroundImage: `url(${Villa3})`,
      },
      "& div.img4": {
        backgroundImage: `url(${Villa4})`,
      },
      "& div.img5": {
        backgroundImage: `url(${Villa5})`,
      },
      "& div.img6": {
        backgroundImage: `url(${Villa6})`,
      },
    },
    homeMidBack: {
      position: "absolute",
      left: 0,
      top: 300,
      width: "100vw",
      minHeight: "100%",
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: `url(${Midback})`,
      zIndex: -5,
    },
    homeMidBottomBack: {
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "100vw",
      height: 280,
      backgroundColor: "white",
      zIndex: -5,
    },
    reservationBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        alignItems: "flex-start",
        flexDirection: "column",
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    reservationSelector: {
      marginRight: 10,
      border: "none",
      backgroundColor: "rgba(0,0,0,0)",
    },
    reservationSubmit: {
      marginLeft: 30,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: "#87effa",
      border: "none",
      [theme.breakpoints.down("sm")]: {
        marginTop: 20,
        width: 300,
        marginLeft: 0,
      },
    },
    reservationSplitter: {
      height: "100%",
      minHeight: 40,
      width: 2,
      backgroundColor: "#f2f3f4",
      marginRight: 26,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    theDateRangePicker: {
      "& .react-daterange-picker__wrapper": {
        display: "none",
      },
    },
  });

export default styles;
