import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/views/home/Home";
import { setAppDimensions, setLocale } from "./redux/actions/app";
import { connect } from "react-redux";
import AOS from "aos";
import Contact from "./components/views/contact/Contact";
import TheVilla from "./components/views/thevilla/TheVilla";
import Discover from "./components/views/discover/Discover";
import Gallery from "./components/views/gallery/Gallery";
import Terms from "./components/views/terms/Terms";
import Translate from "./Translate/Translate";
import { RootState } from "./redux/reducers";
import LanguageRoute from "./components/LanguageRoute";

AOS.init();
interface State {}

interface DispatchProps {
  doSetAppDimensions: (width: number, height: number) => void;
  doSetLocale: (locale: string) => void;
}

interface StateProps {
  currentLocale: string;
}

type Props = DispatchProps & StateProps;

class App extends Component<Props, State> {
  updateDimensions = () => {
    this.props.doSetAppDimensions(window.innerWidth, window.innerHeight);
  };

  componentDidMount = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    if (!this.props.currentLocale) {
      Translate.setLocale("en");
      Translate.setI18n("en");
      this.props.doSetLocale("en");
      this.setState({
        currentLocale: "en",
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const { currentLocale } = this.props;
    return (
      (currentLocale && (
        <div className="App" lang={currentLocale}>
          <BrowserRouter>
            <Switch>
              <Route
                path={`/:language(nl|fr|en)?/${Translate.translate(
                  "GALLERY_LINK",
                )}`}
                render={(props) => <Gallery {...props} />}
              />
              <Route
                path={`/:language(nl|fr|en)?/${Translate.translate(
                  "DISCOVER_LINK",
                )}`}
                render={(props) => <Discover {...props} />}
              />
              <Route
                path={`/:language(nl|fr|en)?/${Translate.translate(
                  "VILLA_LINK",
                )}`}
                render={(props) => <TheVilla {...props} />}
              />
              <Route
                path={`/:language(nl|fr|en)?/${Translate.translate(
                  "CONTACT_LINK",
                )}`}
                render={(props) => <Contact {...props} />}
              />
              <Route path="/terms" render={(props) => <Terms {...props} />} />
              <Route
                path="/:language"
                render={(props) => <Home {...props} />}
              />
              <LanguageRoute path="/" component={Home} />
            </Switch>
          </BrowserRouter>
        </div>
      )) ||
      null
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    currentLocale: state.app.currentLocale,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    doSetAppDimensions: (width: number, height: number) => {
      dispatch(setAppDimensions(width, height));
    },
    doSetLocale: (locale: string) => {
      dispatch(setLocale(locale));
    },
  };
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(App);
