import { TextField, withStyles } from "@material-ui/core";

export default withStyles({
  root: {
    color: "black",
    "label + &": {
      marginTop: -10,
    },
    "& input": {
      color: "black",
      zIndex: 9999,
    },
    "& textarea": {
      color: "black",
      zIndex: 9999,
    },
    "& label": {
      color: "black",
      zIndex: 9999,
    },
    "& label.Mui-focused": {
      color: "black",
      zIndex: 9999,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        color: "black",
        zIndex: 900,
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&:focus fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField);
