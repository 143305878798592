const reviews = [
  {
    title: "Definitely recommended",
    stars: 4,
    text: (
      <>
        Enjoyed this fantastic place! In Ibiza it is always a little wait and
        see if the description and the photos correspond to reality but this one
        is really top notch. Lovely pool, comfy sunbeds, a terrace with outdoor
        kitchen, gas BBQ and bar, a modern kitchen... Everything has been
        thought of! Super nice location in the mountains but not too remote
        either, with some cool beach bars and restaurants. We would like a house
        like that. :) Definitely a recommended!
      </>
    ),
    name: "Viviane De Clerck",
    city: "Antwerp Belgium",
  },
  {
    title: "We'll be back!",
    stars: 4,
    text: (
      <>
        What a fantastic place this is. So relax in the mountains but still with
        beautiful view of the sea. We will definitely come back here!
      </>
    ),
    name: "Lydia De Roeck",
    city: "Stekene Belgium",
  },
];

export default reviews;
