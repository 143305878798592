import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import logo from "../../assets/img/logo.svg";
import styles from "./Header.styles";
import NavigationMenu from "./NavigationMenu";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import LanguageMenu from "./LanguageMenu";

interface State {}

interface OwnProps {
  classes?: any;
  blackBack?: boolean;
}

type Props = OwnProps;

class Header extends Component<Props, State> {
  render() {
    const { classes } = this.props;


    return (
      <header style={{ width: "100%" }}>
        <Grid
          container
          className={classnames(classes.root, {
            [classes.rootBlack]: this.props.blackBack,
          })}
        >
          <Grid
            container
            direction="row"
            className={classnames(classes.headerHolder, {
              [classes.headerBlack]: this.props.blackBack,
            })}
          >
            <div className={classes.logoHolder}>
              <NavLink
                to={`/`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <img
                  src={logo}
                  className={"App-logo animate__animated animate__fadeIn"}
                  alt="logo"
                  style={{ zIndex: 1, width: 240, height: 100 }}
                />
              </NavLink>
            </div>
            <div className={classes.logoHolderMobile}>
              <NavLink
                to={`/`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <img
                  src={logo}
                  className={"App-logo animate__animated animate__fadeIn"}
                  alt="logo"
                  style={{ zIndex: 1, width: 180, height: 75, marginTop: 12 }}
                />
              </NavLink>
            </div>
            <div style={{ flex: 1 }} />
            <div className={classes.menuHolder}>

              {/*// @ts-ignore*/}
              <NavigationMenu />
            </div>
            <div className={classes.menuHolderMobile}>
              {/*// @ts-ignore*/}
              <NavigationMenu />
            </div>
            <div className={classes.languageHolderMobile}>
              <LanguageMenu />
            </div>
          </Grid>
        </Grid>
      </header>
    );
  }
}

export default withStyles(styles)(Header);
