import { TextField, withStyles } from "@material-ui/core";

export default withStyles({
  root: {
    backgroundColor: "rgba(0,0,0,0)",
    color: "black",
    "label + &": {
      marginTop: -10,
    },
    "& input": {
      color: "black",
      padding: "8px 4px",
      marginBottom: 8,
    },
    "& textarea": {
      color: "black",
      marginBottom: 8,
    },
    "& label": {
      color: "black",
    },
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiOutlinedInput-root": {
      "&:focus": {
        outline: "none !important",
        WebkitTapHighlightColor: "rgba(0,0,0,0)",
      },
      "& fieldset": {
        borderRadius: 0,
        border: "none",
        borderColor: "black",
        borderBottom: "1px solid black",
        color: "white",
        backgroundColor: "rgba(0,0,0,0)",
        outline: "none !important",
        WebkitTapHighlightColor: "rgba(0,0,0,0)",
      },
      "&:hover fieldset": {
        borderRadius: 0,
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: "white",
        outline: "none !important",
        WebkitTapHighlightColor: "rgba(0,0,0,0)",
      },
      "&:not(:hover) fieldset": {
        borderRadius: 0,
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: "black",
        outline: "none !important",
        WebkitTapHighlightColor: "rgba(0,0,0,0)",
      },
      "&:focus fieldset": {
        borderRadius: 0,
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: "black",
        outline: "none !important",
        WebkitTapHighlightColor: "rgba(0,0,0,0)",
      },
    },
  },
})(TextField);
