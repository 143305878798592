export const SET_LOCALE = "SET_LOCALE";
export const SET_DIMENSIONS = "SET_DIMENSIONS";

interface SetAppLocale {
  type: typeof SET_LOCALE;
  locale: string;
}

interface SetWindowDimensions {
  type: typeof SET_DIMENSIONS;
  windowWidth: number;
  windowHeight: number;
}

export interface AppState {
  currentLocale: string | undefined;
  windowWidth: number | undefined;
  windowHeight: number | undefined;
}

export type AppActionType = SetAppLocale | SetWindowDimensions;
