import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";

const styles = (theme: Theme) =>
  createStyles({
    bottomFormHolder: {
      justifyContent: "center",
      alignItems: "center",
    },
    bottomFormInverse: {
      backgroundImage: "none",
    },
    bottomFormSection: {
      maxWidth: 900,
      justifyContent: "flex-start",
      alignItems: "center",
      color: "white",
    },
    bottomFormSectionInverse: {
      color: "black",
    },
    leftField: {
      paddingRight: 16,
      [theme.breakpoints.down("sm")]: {
        paddingRight: 0,
      },
    },
    rightField: {
      paddingLeft: 16,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
      },
    },
    buttonHolder: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: 20,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    rightBut: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-end",
      },
    },
    leftText: {
      paddingRight: 10,
      [theme.breakpoints.down("sm")]: {
        paddingRight: 0,
      },
    },
    rightText: {
      paddingLeft: 10,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
      },
    },
  });

export default styles;
