import React, { Component } from "react";
import { Grid, withStyles } from "@material-ui/core";
import styles from "./PageView.styles";

interface State {}

interface OwnProps {
  classes?: any;
}

type Props = OwnProps;

class PageView extends Component<Props, State> {
  componentDidMount = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container direction="column" className={classes.root}>
        <Grid container direction="column" className={classes.pageHolder}>
          {this.props.children}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(PageView);
