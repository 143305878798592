import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  withScriptjs,
} from "react-google-maps";
import MarkerIcon from "../../../assets/img/marker.png";

const GoogleMapZ = (props) => (
    <GoogleMap defaultZoom={15} defaultCenter={{ lat: 39.00462839278018, lng: 1.3055074423303923 }}>
      <Marker position={{ lat:39.00422974742027, lng: 1.3054710208518399  }} icon={MarkerIcon} />
    </GoogleMap>
);

export default withScriptjs(withGoogleMap(GoogleMapZ));