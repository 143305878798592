import { createStyles, Theme } from "@material-ui/core";
import { ThemeAbstracts } from "../../../config/MuiTheme";
import ContactBack from "../../../assets/img/contactback.jpg";
import Villa1 from "../../../assets/img/homevilla1.jpg";
import Villa2 from "../../../assets/img/homevilla2.jpg";
import Villa3 from "../../../assets/img/homevilla3.jpg";

const styles = (theme: Theme) =>
  createStyles({
    wideBlock: {
      position: "relative",
      justifyContent: "center",
      alignItems: "space-between !important",
    },
    midSH: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: 10,
      paddingRight: 10,
    },
    contactBack: {
      position: "absolute",
      left: 0,
      bottom: 280,
      width: "100vw",
      minHeight: "100%",
      backgroundPosition: "center bottom",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: `url(${ContactBack})`,
      zIndex: -5,
    },
    contactBottomBack: {
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "100vw",
      height: 280,
      backgroundColor: "white",
      zIndex: -5,
    },
    pageInnerHolder: {
      width: "100%",
      maxWidth: ThemeAbstracts.layout.maxPageWidth,
      justifyContent: "center",
      alignItems: "center",
    },
    firstImagesHolder: {
      flexDirection: "row",
    },
    firstImages: {
      minHeight: 400,
      width: "42%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "&.hideSmall": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
    firstImagesImg: {
      position: "relative",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      "& div": {
        width: "98%",
        height: "100%",
        minHeight: "100%",
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        transition: "all 0.3s",
        "&:hover": {
          transform: "scale(1.5)",
        },
      },
      "& div.img1": {
        backgroundImage: `url(${Villa1})`,
      },
      "& div.img2": {
        backgroundImage: `url(${Villa2})`,
      },
      "& div.img3": {
        backgroundImage: `url(${Villa3})`,
      },
    },
  });

export default styles;
