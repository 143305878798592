import {ButtonBase, Grid, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {RootState} from "../../../redux/reducers";
import Footer from "../../partials/Footer";
import Header from "../../partials/Header";
import PageView from "../../partials/PageView";
import styles from "./Discover.styles";
import {connect} from "react-redux";
import Helmet from "react-helmet";
import classNames from "classnames";
import BigTitle from "../../text/BigTitle";
import TinyTitle from "../../text/TinyTitle";
import NormalTitle from "../../text/NormalTitle";
import NormalText from "../../text/NormalText";
import ContactForm from "../contact/ContactForm";
import CalaSalada from "../../../assets/img/calasalada.jpg";
import CalaSaladeta from "../../../assets/img/calasaladeta.jpg";
import CalaGracioneta from "../../../assets/img/calagracioneta.jpg";
import CasaBassa from "../../../assets/img/casabassa.jpg";
import CasaDHort from "../../../assets/img/casadhort.jpg";


import BikeIbiza from "../../../assets/img/bikeibiza.jpg";
import CafeMambo from "../../../assets/img/cafemambo.jpg";
import CafeDelMar from "../../../assets/img/cafedelmar.jpg";
import CanPilot from "../../../assets/img/canpilot.jpg";
import CanPou from "../../../assets/img/canpou.jpg";
import Formentera from "../../../assets/img/formentera.jpg";
import BlueMarlin from "../../../assets/img/bluemarlin.jpg";
import PuntaGalera from "../../../assets/img/puntagalera.jpg";
import SunsetAshram from "../../../assets/img/sunsetashram.jpg";
import Diving from "../../../assets/img/diving.jpg";
import Airport from "../../../assets/img/airport.jpg";
import Map from "../../../assets/img/map.png";
import Atzzo from "../../../assets/img/atzzo.jpg";
import OldTown from "../../../assets/img/oldtown.jpg";
import Besos from "../../../assets/img/besos.jpg";
import Pacha from "../../../assets/img/pacha.jpg";
import Ushuaia from "../../../assets/img/ushuaia.jpg";
import Padel from "../../../assets/img/padel.jpg";
import Lio from "../../../assets/img/lio.jpg";
import EsTorrent from "../../../assets/img/estorrent.jpg";
import ExBeach from "../../../assets/img/exbeach.jpg";
import Translate from "../../../Translate/Translate";
import TranslatedComponent from "../../TranslatedComponent";
import emailjs from "emailjs-com";
import axios from 'axios';
import { casahertaAnonymousApiURL }  from '../../../config/axios';

interface State {
    height: number;
    activeItem: number;
    email: string | undefined;
    message: string | undefined;
    sendingMail: boolean;
    emailSuccess: boolean;
    captcha: string | undefined;
    link_download: string | undefined;
}

interface OwnProps {
    classes?: any;
}

interface StateProps {
    windowWidth: number | undefined;
    windowHeight: number | undefined;
    currentLocale: string;
}

interface Item {
    title: String;
    desc: any;
    img: any | undefined;
}

type Props = OwnProps & StateProps;

class Discover extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            height: 0,
            activeItem: 0,
            email: undefined,
            message: undefined,
            sendingMail: false,
            emailSuccess: false,
            captcha: undefined,
            link_download : window.location.origin + "/casaherta.pdf",
        };
    }



    handleScroll = () => {
    };

    componentDidMount() {
        this.setState({
            height: window.innerHeight,
        });
    }

    setActiveItem = (activeItem) => {
        this.setState({activeItem});
    };

    sendMail = () => {
        this.setState({
            emailSuccess: false,
        });
        //get state email and send mail
        // this.setState({
        //     emailSuccess: true,
        // }
        // check validate mail expression
        // if (validateEmail(this.state.email)) {
        if (this.validateEmail(this.state.email)) {
            var templateParams = {
                email: this.state.email,
                to_name: 'You',
                link_download: this.state.link_download,
                from_name: 'Casaherta',
                message: 'This is pdf file',
                reply_to: this.state.email,
                to_email: this.state.email
            };
            this.setState(
                {
                    email: this.state.email,
                    sendingMail: true
                },
                () => {
                    var subscriber = {
                        email : this.state.email,
                        link_download: this.state.link_download,
                    }
                    axios.post(`${casahertaAnonymousApiURL}/subscribers/add`,{ subscriber })
                        .then(res => {
                            this.setState({
                                sendingMail: false,
                                emailSuccess: true,
                                email: '',
                            });
                        })
                        .catch(error => console.log(error));

                },
            );
        }else {
            if(this.state.email === '' || this.state.email === undefined){
                this.setState({
                    message: "Please enter your email!",
                });
            }else{
                this.setState({
                    message: "Email invalid!",
                });
            }
        }
    }
    validateEmail = (email) => {
        if(email === '' || email === undefined) {
            return false;
        }
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    onChangeEmail = (email) => {
        this.setState({
            email: email,
        });
    }

    render() {
        const {classes, windowHeight, windowWidth} = this.props;
        const {height, activeItem} = this.state;
        const mobile = windowWidth && windowWidth < 700 ? true : false;
        const theWindowHeight = mobile ? height : windowHeight || 700;

        const data: { id: number; items: Item[] }[] = [
            {
                id: 1,
                items: [
                    {
                        title: "Café Mambo",
                        desc: Translate.translate("DISCOVERTEXT1"),
                        img: CafeMambo,
                    },
                    {
                        title: "Cafe Del Mar",
                        desc: Translate.translate("DISCOVERTEXT2"),
                        img: CafeDelMar,
                    },
                    {
                        title: "Can Pilot",
                        desc: Translate.translate("DISCOVERTEXT3"),
                        img: CanPilot,
                    },
                    {
                        title: "Restaurant Can Pou",
                        desc: Translate.translate("DISCOVERTEXT4"),
                        img: CanPou,
                    },
                    {
                        title: "Formentera & Beso Beach Restaurant",
                        desc: Translate.translate("DISCOVERTEXT5"),
                        img: Formentera,
                    },
                    {
                        title: "Cala Jondal - Yemanja Restaurant & Blue Marlin",
                        desc: Translate.translate("DISCOVERTEXT6"),
                        img: BlueMarlin,
                    },
                ],
            },
            {
                id: 2,
                items: [
                    {
                        title: "Bike Ibiza. Love, Ride, Ibiza",
                        desc: Translate.translate("DISCOVERTEXT8"),
                        img: BikeIbiza,
                    },
                    {
                        title: "Diving",
                        desc: Translate.translate("DISCOVERTEXT9"),
                        img: Diving,
                    },
                    {
                        title: "Atzzo Chatt",
                        desc: Translate.translate("DISCOVERTEXT10"),
                        img: Atzzo,
                    },
                    {
                        title: "Pacha Club",
                        desc: Translate.translate("DISCOVERTEXT11"),
                        img: Pacha,
                    },
                    {
                        title: "Ushuaïa",
                        desc: Translate.translate("DISCOVERTEXT12"),
                        img: Ushuaia,
                    },
                    {
                        title: "Lio Cabaret Club",
                        desc: Translate.translate("DISCOVERTEXT13"),
                        img: Lio,
                    },
                    {
                        title: "Padel Club",
                        desc: Translate.translate("DISCOVERTEXT26"),
                        img: Padel,
                    },
                ],
            },
            {
                id: 3,
                items: [
                    {
                        title: "Dalt Vila (Old Town)",
                        desc: Translate.translate("DISCOVERTEXT14"),
                        img: OldTown,
                    },
                    {
                        title: "Cala Salada",
                        desc: Translate.translate("DISCOVERTEXT15"),
                        img: CalaSalada,
                    },
                    {
                        title: "Cala Saladeta",
                        desc: Translate.translate("DISCOVERTEXT16"),
                        img: CalaSaladeta,
                    },
                    {
                        title: "Cala Gracioneta",
                        desc: Translate.translate("DISCOVERTEXT17"),
                        img: CalaGracioneta,
                    },
                    {
                        title: "Cala Bassa",
                        desc: Translate.translate("DISCOVERTEXT27"),
                        img: CasaBassa,
                    },
                    {
                        title: "Cala d’Hort",
                        desc: Translate.translate("DISCOVERTEXT28"),
                        img: CasaDHort,
                    },
                    {
                        title: "Punta Galera",
                        desc: Translate.translate("DISCOVERTEXT20"),
                        img: PuntaGalera,
                    },
                    {
                        title: "Es Torrent",
                        desc: Translate.translate("DISCOVERTEXT21"),
                        img: EsTorrent,
                    },
                    {
                        title: "Experimental Beach",
                        desc: Translate.translate("DISCOVERTEXT22"),
                        img: ExBeach,
                    },
                    {
                        title: "Sunset Ashram",
                        desc: Translate.translate("DISCOVERTEXT23"),
                        img: SunsetAshram,
                    },
                    {
                        title: "Besos de Ibiza (Dutch website)",
                        desc: Translate.translate("DISCOVERTEXT24"),
                        img: Besos,
                    },
                ],
            },
            {
                id: 4,
                items: [
                    {
                        title: "Airport",
                        desc: Translate.translate("DISCOVERTEXT25"),
                        img: Airport,
                    },
                ],
            },
            {
                id: 5,
                items: [
                    {
                        title: "IBIZA ISLAND MAP",
                        desc: Translate.translate("MAPDESC"),
                        img: Map,
                    },
                ],
            },
        ];

        return (
            <TranslatedComponent {...this.props}>
                <PageView>
                    <Helmet>
                        <title>
                            Casa Herta d'Ibiza | Home - Book your Ibiza hideaway now!
                        </title>
                        <meta
                            name="title"
                            content="Casa Herta d'Ibiza | Home - Book your Ibiza hideaway now!"
                        />
                        <meta
                            name="description"
                            content="Casa Herta d'Ibiza is a luxurious villa in the heart of Ibiza. Book your stay now and experience a lavish Ibiza lifestyle. The house offers a swimmingpool, bedrooms, bathrooms and suits up to 7 guests."
                        />
                        <meta
                            name="keywords"
                            content="casa, herta, ibiza, home, vacation, holiday, rental, summer, stay, island, beaches, party"
                        />
                    </Helmet>
                    <Grid
                        container
                        className={classNames(
                            classes.wideBlock,
                            classes.head,
                            classes.headmin,
                        )}
                    >
                        <Grid
                            container
                            style={{
                                flex: 0,
                            }}
                        >
                            <Header/>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            className={classNames(classes.wide, classes.headTop)}
                            style={{
                                flex: 1,
                                minHeight: 600,
                            }}
                        >
                            <div style={{textAlign: "center"}}>
                                <BigTitle
                                    style={{
                                        maxWidth: 800,
                                        textAlign: "center",
                                        marginBottom: 20,
                                        marginTop: 120,
                                    }}
                                >
                                    {Translate.translate("DISCOVERTITLE")}
                                </BigTitle>
                                <TinyTitle
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    {Translate.translate("DISCOVERSUBTITLE")}
                                </TinyTitle>
                            </div>
                        </Grid>
                        <Grid
                            container
                            style={{
                                flex: 0,
                                display: "flex",
                                backgroundColor: "#ffdeba",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Grid container className={classes.reservationHolder}>
                                <nav>
                                    <ul className={classes.villaMenu}>
                                        <li>
                                            <ButtonBase
                                                onClick={() => this.setActiveItem(0)}
                                                className={activeItem === 0 ? "active" : ""}
                                            >
                                                {Translate.translate("FOODNDRINKS")}
                                            </ButtonBase>
                                        </li>
                                        <li>
                                            <ButtonBase
                                                onClick={() => this.setActiveItem(1)}
                                                className={activeItem === 1 ? "active" : ""}
                                            >
                                                {Translate.translate("ACTIVITIES")}
                                            </ButtonBase>
                                        </li>
                                        <li>
                                            <ButtonBase
                                                onClick={() => this.setActiveItem(2)}
                                                className={activeItem === 2 ? "active" : ""}
                                            >
                                                {Translate.translate("SIGHTSEEING")}
                                            </ButtonBase>
                                        </li>
                                        <li>
                                            <ButtonBase
                                                onClick={() => this.setActiveItem(3)}
                                                className={activeItem === 3 ? "active" : ""}
                                            >
                                                {Translate.translate("AIRPORT")}
                                            </ButtonBase>
                                        </li>
                                        <li>
                                            <ButtonBase
                                                onClick={() => this.setActiveItem(4)}
                                                className={activeItem === 4 ? "active" : ""}
                                            >
                                                {Translate.translate("ISLANDMAP")}
                                            </ButtonBase>
                                        </li>
                                    </ul>
                                </nav>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        style={{
                            flex: 0,
                            display: "flex",
                            backgroundColor: "white",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            container
                            className={classes.pageInnerHolder}
                            style={{marginLeft: 20, marginRight: 20, paddingTop: 60}}
                        >
                            {data[activeItem].items.map((item, key) => {
                                return (

                                    activeItem !== 4 ? (
                                            <Grid
                                                container
                                                direction="row"
                                                key={key}
                                                style={{marginTop: 40, marginBottom: 100}}
                                                className={key % 2 === 0 ? classes.dataReversed : null}
                                            >
                                                {item.img && (
                                                    <Grid item xs={12} md={4}>
                                                        <img
                                                            src={item.img}
                                                            alt={String(item.title)}
                                                            width="100%"
                                                            data-aos={
                                                                key % 2 === 0 ? "slide-left" : "slide-right"
                                                            }
                                                            data-aos-duration="1000"
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={item.img ? 8 : 12}
                                                    style={{
                                                        paddingLeft: key % 2 === 0 ? 0 : 40,
                                                        paddingRight: 40,
                                                    }}
                                                >
                                                    <div
                                                        data-aos={key % 2 === 0 ? "slide-right" : "slide-left"}
                                                        data-aos-duration="1000"
                                                        style={{width: "100%"}}
                                                    >
                                                        <NormalTitle
                                                            style={{
                                                                marginBottom: 20,
                                                            }}
                                                            variant="h2"
                                                        >
                                                            {item.title}
                                                        </NormalTitle>
                                                    </div>
                                                    <div
                                                        data-aos={key % 2 === 0 ? "slide-right" : "slide-left"}
                                                        data-aos-duration="1000"
                                                    >
                                                        <NormalText>{item.desc}</NormalText>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )
                                        //else
                                        : (
                                            <Grid
                                                container
                                                direction="row"
                                                key={key}
                                                style={{marginTop: 40, marginBottom: 100}}
                                                // className={}
                                            >
                                                {item.img && (
                                                    <Grid item xs={12} md={6}>
                                                        <img
                                                            src={item.img}
                                                            alt={String(item.title)}
                                                            width="100%"
                                                            data-aos={
                                                                key % 2 === 0 ? "slide-left" : "slide-right"
                                                            }
                                                            data-aos-duration="1000"
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    style={{
                                                        paddingLeft: key % 2 === 0 ? 0 : 40,
                                                        paddingRight: 40,
                                                        // marginTop: 200,
                                                    }}
                                                    className={classes.mapHolder}
                                                >
                                                    <div
                                                        data-aos={key % 2 === 0 ? "slide-right" : "slide-left"}
                                                        data-aos-duration="1000"
                                                        style={{width: "100%"}}
                                                    >
                                                        <NormalTitle
                                                            style={{
                                                                marginBottom: 20,
                                                                fontSize: 40,
                                                                // letterSpacing: 0.5,
                                                            }}
                                                            variant="h2"
                                                        >
                                                            {item.title}
                                                        </NormalTitle>
                                                    </div>
                                                    <div
                                                        data-aos={key % 2 === 0 ? "slide-right" : "slide-left"}
                                                        data-aos-duration="1000"
                                                    >
                                                        <NormalText>
                                                            <p>Looking for the hotspots on the Island?
                                                                <br />
                                                                Download our Ibiza Guide and get to know our favourite restaurants, beaches and hangouts.
                                                            </p>
                                                            <p>
                                                                Submit your e-mail to receive the ultimate Ibiza guide (NL) by mail.
                                                            </p>
                                                            <input type="text" value={this.state.email} onChange={(e) => this.onChangeEmail(e.target.value)}/>
                                                                <button className={classes.buttonSubmit} onClick={ () => this.sendMail()}>SUBMIT</button><br/>

                                                            {!this.state.emailSuccess && <p>{this.state.message}</p>}
                                                            {this.state.emailSuccess && <p>Welcome to paradise!</p>}
                                                        </NormalText>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )
                                );
                            })}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        style={{
                            flex: 0,
                            display: "flex",
                            backgroundColor: "white",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            container
                            className={classes.pageInnerHolder}
                            style={{
                                marginTop: 120,
                                marginBottom: 120,
                                marginLeft: 20,
                                marginRight: 20,
                            }}
                        >
                            <ContactForm
                                title={Translate.translate("CONTACTUS")}
                                sendText={Translate.translate("CONTACTSEND")}
                                titleVariant="h2"
                            />
                        </Grid>
                    </Grid>
                    <Footer/>
                </PageView>
            </TranslatedComponent>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        windowWidth: state.app.windowWidth,
        windowHeight: state.app.windowHeight,
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Discover));
