import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import CustomTextField from "../../input/CustomTextField";
import StyledButton from "../../button/StyledButton";
import styles from "./ContactForm.styles";
import classNames from "classnames";
import CustomTextFieldBlack from "../../input/CustomTextFieldBlack";
import emailjs from "emailjs-com";
import swal from "sweetalert";
import Loader from "../../loader/Loader";
import ReCAPTCHA from "react-google-recaptcha";
import NormalTitle from "../../text/NormalTitle";
import TinyTitle from "../../text/TinyTitle";
import Translate from "../../../Translate/Translate";

interface State {
  lastName: string | undefined;
  firstName: string | undefined;
  email: string | undefined;
  tel: string | undefined;
  question: string | undefined;
  emailSuccess: boolean;
  sendingMail: boolean;
  captcha: string | undefined;
}

interface OwnProps {
  classes?: any;
  title: string;
  subTitle?: string;
  sendText: string;
  inverse?: boolean;
  id?: string;
  style?: any;
  titleVariant?: string;
}

type Props = OwnProps;

class ContactForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      lastName: undefined,
      firstName: undefined,
      email: undefined,
      tel: undefined,
      question: undefined,
      emailSuccess: false,
      sendingMail: false,
      captcha: undefined,
    };
  }

  recaptchaRef: any = React.createRef();

  onChange = (value: any) => {
    this.setState({
      captcha: value,
    });
  };

  sendForm = () => {
    const { lastName, firstName, email, tel, question, captcha } = this.state;
    if (
      lastName &&
      lastName.length &&
      firstName &&
      firstName.length &&
      email &&
      email.length &&
      tel &&
      tel.length &&
      question &&
      question.length
    ) {
      var templateParams = {
        from_name: firstName,
        first_name: firstName,
        last_name: lastName,
        email: email,
        tel: tel,
        question: question,
        reply_to: email,
        "g-recaptcha-response": captcha,
      };
      this.setState(
        {
          sendingMail: true,
        },
        () => {
          emailjs
            .send(
              "service_ni3f8et",
              "template_gd8ewlg",
              templateParams,
              "user_Sq4BqO28pvNWoIv8S1j6i",
            )
            .then(
              () => {
                this.setState({
                  sendingMail: false,
                  emailSuccess: true,
                });
              },
              (error) => {
                this.setState(
                  {
                    sendingMail: false,
                  },
                  () => {
                    swal(
                      Translate.translate("PROBLEM"),
                      Translate.translate("PROBLEMSENDING"),
                      "error",
                    );
                  },
                );
              },
            );
        },
      );
    } else {
      swal(
        Translate.translate("PROBLEM"),
        Translate.translate("PROBLEMFIELDS"),
        "error",
      );
    }
  };

  onInputChange = (field: string, e: any) => {
    this.setState({
      ...this.state,
      [field]: e.target.value,
    });
  };

  render() {
    const {
      classes,
      sendText,
      inverse,
      id,
      style,
      title,
      subTitle,
      titleVariant,
    } = this.props;
    const {
      lastName,
      firstName,
      email,
      tel,
      question,
      emailSuccess,
      sendingMail,
      captcha,
    } = this.state;
    const TextComponent = inverse ? CustomTextFieldBlack : CustomTextField;
    const mobile = window.innerWidth < 700 ? true : false;
    return (
      <Grid
        id={id || "contact"}
        container
        direction="column"
        className={classNames(
          classes.bottomFormHolder,
          inverse ? classes.bottomFormInverse : null,
        )}
        style={style || {}}
      >
        {emailSuccess && (
          <Grid
            container
            className={classNames(
              classes.bottomFormSection,
              inverse ? classes.bottomFormSectionInverse : null,
            )}
            direction="column"
          >
            <NormalTitle
              style={{ marginTop: 40, marginBottom: 0, color: "black" }}
              variant={titleVariant || "h1"}
            >
              <span className="bedankt">{Translate.translate("THANKYOU")}</span>
            </NormalTitle>
            <TinyTitle style={{ marginBottom: 60, color: "black" }}>
              {Translate.translate("THANKYOUMESSAGE")}
            </TinyTitle>
          </Grid>
        )}
        {!emailSuccess && (
          <Grid
            container
            className={classNames(
              classes.bottomFormSection,
              inverse ? classes.bottomFormSectionInverse : null,
            )}
            direction="column"
          >
            <NormalTitle
              style={{
                marginTop: mobile ? 60 : 20,
                marginBottom: subTitle ? 0 : 30,
                textAlign: "center",
              }}
              variant={titleVariant || "h1"}
            >
              {title}
            </NormalTitle>
            {(subTitle && (
              <TinyTitle
                style={{ marginBottom: 10, marginTop: 20, color: "black" }}
              >
                {subTitle}
              </TinyTitle>
            )) ||
              null}
            <Grid container direction="column">
              <TextComponent
                key="firstname"
                required={true}
                id="firstName"
                name="Firstname"
                fullWidth
                label={Translate.translate("FIRSTNAME")}
                autoComplete="given-name"
                placeholder={Translate.translate("FIRSTNAME")}
                value={firstName || ""}
                margin="normal"
                onChange={(e) => this.onInputChange("firstName", e)}
                variant="outlined"
              />
              <TextComponent
                key="lastname"
                required={true}
                id="name"
                name="name"
                fullWidth
                label={Translate.translate("NAME")}
                autoComplete="family-name"
                placeholder={Translate.translate("NAME")}
                value={lastName || ""}
                margin="normal"
                onChange={(e) => this.onInputChange("lastName", e)}
                variant="outlined"
              />
              <TextComponent
                type="email"
                required={true}
                id="email"
                name="email"
                fullWidth
                label={Translate.translate("EMAIL")}
                autoComplete="email"
                placeholder={Translate.translate("EMAIL")}
                value={email || ""}
                margin="normal"
                onChange={(e) => this.onInputChange("email", e)}
                variant="outlined"
              />
              <TextComponent
                required={true}
                id="tel"
                name="tel"
                fullWidth
                label={Translate.translate("PHONE")}
                autoComplete="none"
                placeholder={Translate.translate("PHONE")}
                value={tel || ""}
                margin="normal"
                onChange={(e) => this.onInputChange("tel", e)}
                variant="outlined"
              />
              <TextComponent
                required={true}
                id="question"
                name="question"
                fullWidth
                label={Translate.translate("MESSAGE")}
                autoComplete="none"
                placeholder={Translate.translate("MESSAGE")}
                value={question || ""}
                margin="normal"
                onChange={(e) => this.onInputChange("question", e)}
                variant="outlined"
                multiline
                rows={3}
              />
            </Grid>
            <div className={classes.buttonHolder}>
              <ReCAPTCHA
                ref={this.recaptchaRef}
                sitekey="6Ld5DCAaAAAAAB2CUtCfPkeDV6YZhJ5TSWP93KO6"
                onChange={this.onChange}
              />
              <div className={classes.rightBut}>
                {captcha && (
                  <StyledButton onClick={() => this.sendForm()}>
                    {sendText}
                  </StyledButton>
                )}
              </div>
            </div>
          </Grid>
        )}
        {(sendingMail && <Loader />) || null}
      </Grid>
    );
  }
}

export default withStyles(styles)(ContactForm);
