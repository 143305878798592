import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Component } from "react";
import Translate from "../../Translate/Translate";
import LanguageIcon from "@material-ui/icons/Language";
import styles from "./LanguageMenu.styles";
import { connect } from "react-redux";
import { RootState } from "../../redux/reducers";
import { withRouter } from "react-router-dom";
import { setLocale } from "../../redux/actions/app";

interface State {
  localeMenu: any;
}

interface StateProps {
  currentLocale: string;
}

interface DispatchProps {
  doSetLocale: (locale: string) => void;
}

interface OwnProps {
  classes?: any;
  history?: any;
}

type Props = OwnProps & DispatchProps & StateProps;

class LanguageMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      localeMenu: undefined,
    };
  }
  changeLocale = (i18n: string): void => {
    if (i18n !== Translate.getCurrentLocale()?.i18n) {
      this.setState(
        {
          localeMenu: undefined,
        },
        () => {
          Translate.setLocale(i18n);
          this.props.doSetLocale(i18n);
          this.props.history.push(`/${i18n}`);
        },
      );
    } else {
      this.setState({
        localeMenu: undefined,
      });
    }
  };

  render() {
    const { localeMenu } = this.state;
    const { classes, currentLocale } = this.props;
    const languages = Translate.getLocaleSwitch(true);

    const createLocaleMenuItems = languages.map((locale, i) => (
      <MenuItem
        key={i}
        onClick={() => this.changeLocale(locale.i18n)}
        disabled={locale.current}
        selected={locale.current}
      >
        {locale.value}
      </MenuItem>
    ));

    return (
      <div className={classes.menuHolder}>
        <IconButton
          color="inherit"
          aria-label="Menu"
          aria-owns={localeMenu ? "locale-menu" : undefined}
          aria-haspopup="true"
          onClick={(e) => this.setState({ localeMenu: e.target })}
          className={classes.theIconButton}
        >
          <LanguageIcon className={classes.languageIcon} />
        </IconButton>
        <Menu
          id="locale-menu"
          elevation={1}
          anchorEl={localeMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={Boolean(localeMenu)}
          onClose={() => this.setState({ localeMenu: null })}
          classes={{ paper: classes.menuPaper }}
        >
          {createLocaleMenuItems}
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return { currentLocale: state.app.currentLocale };
};

function mapDispatchToProps(dispatch: any) {
  return {
    doSetLocale: (locale: string) => {
      dispatch(setLocale(locale));
    },
  };
}


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(// @ts-ignore
       withStyles(styles)(LanguageMenu)),
);
