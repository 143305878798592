import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  simpleText: {
    fontSize: 18,
    fontFamily: "Articulat CF, sans-serif",
    width: "100%",
    textAlign: "left",
    [theme.breakpoints.down("lg")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 14,

    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      overflowWrap: 'break-word',
    },
  },
}));

const NormalText = (props: any) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.simpleText}
      style={props.style ? { ...props.style } : {}}
    >
      {props.children}
    </Typography>
  );
};

export default NormalText;
